@import "../../../../resources/styles/media";
@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";

.openDialogButton {
  min-width: auto !important; // overrides styles from ActionButton
  margin-left: 0.0625rem;
}

.buttonContainer {
  display: inline-flex;
}

.openDialogIcon {
  height: 100% !important; // overrides styles from material UI icons
  margin-left: 0.5rem;
  margin-right: 0.5rem !important; // overrides styles from ActionButton
}

.vcsErrorPanel {
  padding: 1rem;
  background-color: @dangerBackground;
  box-shadow: inset 3px 0 0 @dangerHeaderText;

  .titleContainer {
    color: @dangerHeaderText;
  }

  .content {
    padding-top: 1rem;
  }
}

.callouts {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}