@import "../../../../resources/styles/colors";
@import "../../../../resources/styles/variables";

.cardContainer {
    &:hover {
        box-shadow: @boxshadow3;
    }
    div {
        max-height: none; //overrides the card content max height
    }
}

.cardContentContainer {
    display: initial; // By default, card displays none at mobile res. We need to override this.
}

.dashboardCards {
    width: auto;
}

.headerRow {
    border: unset;
}

.bodyRow {
    border: unset;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    &:last-child {
        border-bottom: none;
    }
}

.card {
    text-align: left;
    padding: 0 0 0 0.25rem;
    margin: 1rem;
    box-shadow: @boxshadow1;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-basis: 30%;
}

.runTaskDetails {
    display: flex;
    flex-direction: row;
}

.runTaskEnvironment {
    padding: 0 0.5rem 0.25rem; // To align nicely with task detail cells.
    font-weight: @bold;
    font-size: 1.0625rem;
}

.runTaskNotes {
    padding: 0.5rem;
    color: @ternaryText;
    font-weight: @normal;
    font-size: 0.6875rem;
    background-color: @ternaryBackground;
    overflow: auto;
}

.environmentName, .tenantName {
    font-weight: @bold;
    font-size: 1.0625rem;
}

.rowHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    justify-content: left;
    font-weight: @semibold;
}

.groupHeader {
    display: flex;
    align-items: center;
    background: @secondaryBackground;
    color: @primaryText;
    overflow: hidden;
    padding: 0.5rem 0;
    font-size: 1.0625rem;
    justify-content: space-between;
}

.tileName {
    padding-left: 0.25rem;
    font-size: 1rem;
}

.runActions {
    padding-left: 0;
}

.emptyCell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1rem;
    font-size: 0.875rem;
}

.nextScheduledRow {
    padding: 0.75rem 0rem;
}