@import "../../resources/styles/variables";
@import "../../resources/styles/colors";

// If you update this, also consider the .drawerOffset in GettingStartedFooter.
@drawer-width: 280px;

.root {
    display: flex;
}

.mainContent {
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-right: -@drawer-width;
    flex-grow: 1;
    // The Raw Task page overflows incorrectly without setting min-width to 0 here.
    // 'white-space: pre' has unexpected overflow results in a flexbox and min-width is the culprit
    min-width: 0;
}

.mainContentShift {
    transition: margin 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    margin-right: 0;
}

.drawer {
    width: @drawer-width;
    flex-shrink: 0;
}

.drawerHidden {
    transition: visibility 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    visibility: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header {
    padding: 0.75rem 1rem;
    background: @shadow60;
    color: @whiteConstant;
    font-size: 1rem;
}

.footer {
    padding: 0.75rem 0;
    margin: 0 1rem;
    border-top: 1px solid @dividerLight;
    h4 {
        margin: 0.25rem 0;
    }
    a, span {
        font-size: 0.875rem;
    }
}

#drawerwrapper {
    h4 {
        font-size: 0.875rem;
        font-weight: 500;
        margin: 1rem 0 0.15rem 0;
    }
}

.drawerPaper {
    background-color: @paper1;
    width: @drawer-width;
    top: auto !important;
    height: auto !important;
    z-index: 10; // Needs to be less than the GettingStartedFooter.
    border-top: 1px solid @dividerLight;
    margin-top: -1px; // Needed when scrolling because of our floating area title.
}

.content {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
}
