@import "../../../../resources/styles/colors";

.buttonContainer {
    display: flex;
    width: 100%;
}

.text {
    // Button text can grow to consume the remaining space, pushing out the icon to the right side of the button container
    flex-grow: 1;
}