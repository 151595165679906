@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables";

.scrollUpButton {
  width: 30px;
  height: 30px;
  background-color: @createReleaseBackground !important;
  padding: 1.5rem;
  z-index: 100;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @whiteConstant;
  opacity: 0.7;
  transition: opacity 0.5s;

}

.ScrollUpButton__Container {
  position: fixed;
  right: -100px;
  bottom: 20px;
  transition: right 0.5s;
  cursor: pointer;
  font-size: 20px;
  .scrollUpButton;
  &:hover {
    opacity: 1;

  }
}

.ScrollUpButton__Toggled {
  right: 20px;
}