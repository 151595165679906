.label {
    display: flex;
    align-items: center;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}