@import "../../resources/styles/media.less";

.grid-container {
    display: grid;

    &.overflow {
        grid-template-columns: auto min-content;
        grid-template-areas: "actions overflow" "subitem .";
    }

    &.noOverflow {
        grid-template-columns: auto;
        grid-template-areas: "actions" "subitem";
    }
}

.actions {
}

.overflow {
}

.subItem {
    display: flex;
    margin-top: 0.5rem;
}

.actionList {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap; // Need this to wrap for mobile.
}

.alignStart {
    justify-content: flex-start;
}

.alignEnd {
    justify-content: flex-end;
}

.actionList > :not(:last-child) {
    margin-right: 0.5rem !important;
}

