@import "../../resources/styles/colors";

.listItem {
    padding: 1.25rem;
    word-wrap: break-word;
    font-size: 0.875rem !important; //14px; This matches the font-size for DataTables
    &:hover {
        background-color: @hover !important;
    }
    // mark.siedle - Oh God, these are hidden away deep in material-ui #plsKillMe
    > div:first-child {
        padding: 0 !important;
        > div:first-child {
            padding: 0.75rem 1rem;
        }
    }
}

.simpleListItem {
    position: relative; //TODO: markse review.
    display: block; //TODO: markse review.
    padding: 0;
    word-wrap: break-word;
    &:hover {
        background-color: @hover !important;
    }
    // mark.siedle - Oh God, these are hidden away deep in material-ui #plsKillMe
    > div {
        padding: 0 !important;
        > div {
            padding: 0.75rem 1rem;
        }
    }
}

.selectedListItem {
    background-color: @hover !important;
}

.loadMore {
    text-align: center;
}

.pagingControlContainer {
    margin: 1.5rem 1rem;
}

.disablePagingEvents {
    pointer-events: none;
}