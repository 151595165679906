@import "../../resources/styles/colors";
@import "../../resources/styles/fonts";
@import "../../resources/styles/variables";
@import "../../resources/styles/media";

.topLevelBlock {
  margin: 1rem;
}

.block {
  border: thin solid @unknownTaskLogBorder;
  border-radius: 0.125rem;
  margin: 1rem 0.25rem;
  font-size: 0.875rem;
  overflow: hidden;
}

.lines {
  .monospace;
  font-size: 0.875rem;
  margin: 1rem 1rem 1rem 0; // Left side is offset already by .body.
  min-height: 0.5rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.blockHeader {
  background: @darkBackground;
  color: @darkText;
  padding: 0.5rem 0.4rem;
  font-weight: @normal;
  display: flex;
  justify-content: space-between;
  border-bottom: inherit;
  margin-bottom: -0.0625rem; // Negative to make this bottom border dissappear into the header box correctly.
  cursor: pointer;
}

.blockExpanderIcon {
  margin-right: 0.4rem;
}

.blockTitle {
  white-space: pre-wrap;
  word-break: break-word;
  margin-right: 1rem;
}

.blockTime {
  @media (min-width: @screen-sm) {
      min-width: 117px;
  }
}

.blockPending {
  .block;

  border-color: @unknownTaskLogBorder;

  > .blockHeader {
    background: @unknownTaskLogBackground;
    color: @unknownTaskLogText;
  }
}

.blockRunning {
  .block;

  border-color: @unknownTaskLogBorder;

  > .blockHeader {
    background: @unknownTaskLogBackground;
    color: @runningTaskLogText;
  }
}

.blockSuccess {
  .block;
  border-color: @successTaskLogBorder;

  > .blockHeader {
    background: @successTaskLogBackground;
    color: @successTaskLogText;
  }
}

.blockSuccessWithWarning {
  .blockSuccess;

  > .blockHeader {
    background: @alertTaskLogBackground;
    color: @alertTaskLogText;
  }
}

.blockSkipped {
  .block;
  border-color: @unknownTaskLogBorder;

  > .blockHeader {
    background: @unknownTaskLogBackground;
    color: @unknownTaskLogText;
  }
}

.blockFailed {
  .block;

  border-color: @dangerTaskLogBorder;

  > .blockHeader {
    background: @dangerTaskLogBackground;
    color: @dangerTaskLogText;
  }
}

.blockCanceled { // Intended to run but didn't, not deployment was cancelled
  .block;

  border-color: @unknownTaskLogBorder;

  > .blockHeader {
    background: @unknownTaskLogBackground;
    color: @unknownTaskLogText;
  }
}

.body {
  min-height: 0.5rem;
  margin-left: 1rem;
}

.progress {
  font-size: 0.7rem;
}

