@import "../../../../../resources/styles/colors";

.packageTableContainer {
  overflow-x: auto; // Needed for mobile.
  .packageTable {
    th {
      font-size: 0.875rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    font-size: 1rem;
    .packageTableRowColumn {
      vertical-align: middle !important;
      padding: 0.25rem 1.125rem;
    }
    .packageTableRowColumnName {
      min-width: 10rem; // Needed for mobile.
    }
    .packageColumn {
      width: 25%
    }
    .latestColumn {
      width: 23%
    }
    .lastColumn {
      width: 23%
    }
    .specificColumn {
      width: 29%
    }
    .versionTextbox {
      width: 18rem !important;
    }
    .specificVersionDiv {
      display: flex;
      align-items: center;
    }
    .inlineDiv {
      display: inline-block;
      .editVersionArea {
        min-width: 6.875rem;
      }
    }
    .radioButtonContainer {
      margin-top: -0.6rem;
      .myRadioButton {
        margin-right: 0.5rem;
      }
    }
  }
}

.actionName {
  font-size: 0.875rem;
  color: @secondaryText;
}

.missingPackageIcon {
  padding-left: 0.5rem;
}
