@import "../../resources/styles/colors.less";

.menuIcon {
    font-size: 1.37500rem;
    height: 2.25rem!important;
    padding: 0!important;
    display:flex;
    align-items: center;
    text-decoration: none;
    color: @primaryText;

    &:visited {
        color: @primaryText;
    }

    &:hover {
        opacity: 0.5;
    }
}

.disabledItem {
    cursor: not-allowed;
    color: @secondaryText;
}

.overflowButton {
    font-size: 1.37500rem;
    height: 2.25rem !important;
    width: 2.25rem !important;
    padding: 0 !important;
    margin-left: 0.375rem !important;
    margin-right: 0.375rem !important;
    display:flex;
    align-items: center;
    text-decoration: none;
    color: @iconNeutral !important;

    &:visited {
        color: @iconNeutral;
    }

    &:hover {
        background-color:transparent !important;
        opacity: 0.5;
    }
}