@import "../../../../resources/styles/colors";

.selected:global(.MuiMenuItem-root) {
    color: @primary;
}

.compact {
    min-height: auto;
}

.menuItem:global(.MuiMenuItem-root){
    width: 100%;
}

.overrideColorsFromMaterialUI:global(.MuiListItem-button){
    &:hover {
        background-color: @hover;
    }
    &:focus {
        background-color: @hover;
    }
}

.overrideMinHeightMediaQueryFromMaterialUI:global(.MuiMenuItem-root) {
    min-height: 48px; // copied from material-ui's base styles, that would otherwise be overriden by a media query
}

.disabled {
    //We need this to make the title tooltip to appear for disabled items, we also need this to be !important
    //otherwise MUI won't play nice.
    pointer-events: auto !important;
}
