@import "../resources/styles/colors";
@import "../resources/styles/variables";
@import "../resources/styles/media";

.root {
    width: 440px;
    min-height: 200px;
    background: @paper2;
    box-shadow: @boxshadow4; // This should visually match our regular popover's shadow.
    overflow: auto;
    @media (max-width: @screen-sm) {
        width: 100%;
    }
}

.emptyState {
    text-align: center;
    display: block;
}

.emptyStateImage {
    margin: 2rem auto;
    div {
        margin: 0 auto;
    }
}

.emptyState code {
    font-size: 0.7rem;
}

.list {
    padding: 0 !important; // Override old MUI.
}

.result {
    font-size: 1rem;
    line-height: 1rem;
    color: @primaryText;
    margin: 0.25rem 0; // Save vertical space where possible.
    font-weight: @normal;
}

.resultSubtext {
    margin: 0; // Save vertical space where possible.
    font-size: 0.875rem;
    line-height: 0.875rem;
}

.showResultsSection {
    display: flex;
    padding: 0 1rem 0.5rem 1rem; // Play carefully here - needs the bottom padding to give us space from the "No Results" text.
}

.showResultsSectionBody {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.listContainer {
    max-height: 550px;
    overflow-y: auto;
}

.menuItem {
    white-space: normal;
    margin: 0.5rem 0;
}
