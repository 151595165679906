@import "../../../resources/styles/colors.less";
@import "../../../resources/styles/variables.less";
@import "../../../resources/styles/media.less";

.container {
    display: flex;
    flex-direction: column;
    width: 400/16rem;
}

.menuContainer {
    overflow-y: auto;
    max-height: 400/16rem;
}

.filterContainer {
    display: flex;
}

.filterFieldContainer {
    width: 100%;
}

.selected {
    padding: 1rem;
    background-color: @primaryDarkest;
    color: @whiteConstant;
    word-break: break-all;
    font-weight: @semibold;
    display: none;
}

@media (max-width: @screen-sm) {
    .selected {
        display: initial;
    }
}

.emptyButton {
    margin-bottom: 15/16rem;
}

.empty {
    padding: 10/16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.description {
    width: 350/16rem;
}

.addSpaceButton {
    width: 121px;
    transform: translate(7px, 6px);
}

.footer {
    padding: 0.5rem 2rem 0.5rem;
    background-color: @primaryBackground;
    color: @primaryText;
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
