@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";
@import "../../../../../resources/styles/base-colors";
.greenTickIcon {
    height: 16px;
    width: 16px;
    fill: @legacyGreen;
    margin-top: 10px;
}

.list {
    list-style-position: inside;
    list-style-type: disc;
}

.upsellContentPanel {
    width: 50%;
    box-shadow: var(--shadow25) -0.875rem 0.875rem 2.8125rem -0.875rem, var(--shadow22) -0.625rem 0.625rem 1.125rem -0.625rem;
}

.upsellHelpPanelDark {
    width: 50%;
    max-width: 800px;
}

.upsellHelpPanelLight {
    width: 50%;
    max-width: 800px;
    background: @primaryBackground;
}