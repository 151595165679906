@import "../../../../resources/styles/colors";

.menuItemDownloadLink {
    // We don't want these to hover differently from other menu items (like buttons) just because they are links
    &:hover {
        color: inherit;
    }

    // Needs to be more specific to override material-ui styles
    &:global(.MuiButtonBase-root) {
        color: @linkText;
    }

    // Override color styles from material-ui
    &:global(.MuiListItem-button) {
        &:hover {
            background-color: @hover;
        }
        &:focus {
            background-color: @hover;
        }
    }
}

.compact {
    min-height: auto;
}

.non-compact:global(.MuiMenuItem-root) {
    min-height: 48px; // copied from material-ui's base styles, that would otherwise be overriden by a media query
}