@import "../../../../resources/styles/base-colors.less";
@import "../../../../resources/styles/colors.less";
@import "../../../../resources/styles/media.less";

.dropDownMenu-white {
  border: 1px solid @selectBorder;
  border-width: 0 0 1px 0;
  height: 2.5rem;
  margin-bottom: 1rem;
  .label {
    height: 2.5rem;
    line-height: 2.5rem;
  }
  .buttonDropDown {
    margin-top: 0.3rem;
  }

  &.error-border {
    box-shadow:0px -2px 0px @errorText inset;
    border-radius: 5px 5px 0 0;
  }
}

.dropDownMenu-grey {
  background: @subtleButtonBackground;
  border: 1px solid @subtleButtonBorder;
  border-radius: 5px;
  height: 2rem;
  .label {
    height: 2rem;
    line-height: 2rem;
  }

  &.error {
    border-bottom: none;
    box-shadow:0px -2px 0px @errorText inset;
    border-radius: 5px 5px 0 0;
  }
}

.dropDownMenu {
  width: 100%;
  min-width: 150px;
}

.gitIcon {
  margin-right: 10px;
  width: 18px;
  height: auto;
  max-height: 18px;

  &.error {
    fill: @errorText !important;
  }
}

.lockIcon {
  margin-left: auto;
}

.warning {
  margin: 0.5rem 1rem;
  color: @alertText;
  font-size: 12/16rem;
  width: 250px;
}

.info {
  margin: 0.5rem 1rem;
  color: @infoText;
  font-size: 12/16rem;
}

.refreshButton {
  width: 2rem !important;
  height: 2rem !important;
}

.value {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.error {
    color: @errorText
  }
}

.selectContainer {
  min-width: 150px;
  @media (max-width: @screen-sm) {
    width: 150px;
  }
}

.fetchButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: @subtleButtonBackground;
}

.fetchButton {
  border: unset !important;
  width: 100% !important;
  margin-right: unset !important;
  &:hover {
    border: unset !important;
  }
}

.helperText {
  max-width: 320px; 
  padding: 0 1rem;
  margin-bottom: 0;
}

.menuListScrollContainer {
  max-height: 400px;
  overflow-y: auto;
}

.noResultsContainer {
  padding: 0.5rem;
  text-align: center;
}

.createBranchLinkDisabled {
  color: @secondaryText;
  cursor: pointer;
}

.menuItem {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}