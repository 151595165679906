.information {
    &:global(.MuiListItem-root.Mui-disabled) {
        // Material UI sets a lower opacity on the menu items if they are disabled.
        // In this case, we don't really have anything that is "disabled" so we opt out of this style
        opacity: inherit;
    }

    &:global(.MuiMenuItem-root) {
        // These information items aren't quite like single line buttons, but can instead have wrapping text content
        white-space: inherit;
    }

    &:global(.MuiButtonBase-root.Mui-disabled) {
        // Allow normal pointer events because we aren't trying to prevent button clicks, text selection, etc.
        pointer-events: inherit;
    }

    // Disabling button hover styles
    &:global(.MuiListItem-button):hover {
        background-color: inherit;
    }

    &:global(.MuiButtonBase-root) {
        // Allow text selection, because why not?
        user-select: inherit;
    }
}
.compact {
    min-height: auto;
}

.overrideMinHeightMediaQueryFromMaterialUI:global(.MuiMenuItem-root) {
    min-height: 48px; // copied from material-ui's base styles, that would otherwise be overriden by a media query
}