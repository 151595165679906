@import "../../../resources/styles/colors.less";
@import "../../../resources/styles/visuallyHiddenButAccessibleStyles.less";

.select {
    width: 100% !important;
    min-width: 0 !important;
    overflow: visible; // Needed to display the floating labels/error messages associated with controls.
    display: flex;
    flex-direction: column;
}

.isDisabled * {
    color: @disabledButtonText !important;
    fill: @disabledButtonText;
}

.filter {
    margin: 0 1rem;
}

.container {
    width: 100%;
}

.empty {
    padding: 10/16rem;
}

.visuallyHidden {
    .visuallyHiddenButAccessible();
}
.secondaryText {
    color: @secondaryText;
    font-size: 0.75rem;
}

.menuListScrollContainer {
    min-width: 150px;
    max-height: 400px;
    overflow-y: auto;
}

.menuItemContent {
    display: flex;
    width: 100%;

    &.danger {
        color: @dangerText;
    }
}

.menuItemContentIcon {
    width: auto;
    margin-right: 1rem;
}

.menuItemContentText {
    flex: 1;
    min-height: 1.5rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
