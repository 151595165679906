@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/variables";

.itemSummary {
    display: grid;
    grid-template-columns: auto min-content;
    margin-top: 16px;
    margin-bottom: 0.25rem;

    color: @ternaryText;
    font-weight: @light;
}

.itemSummaryMore {
  white-space: nowrap;
  margin-right: -35px;
}

.itemSummaryFields {
    overflow: hidden;
    height: 16px;
}

.itemSummaryField {
    display: inline-block;
    margin-right: 1.5rem;
}

.itemSummaryFieldTitle {
    margin-right: 0.25rem;
    font-weight: @normal;
}
