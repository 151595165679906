@import "../../resources/styles/colors";
@import "../../resources/styles/media";

@externalMargin: 1rem;

.templates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.templates li {
  display:flex;
}
.categoryTemplates > div, .categories > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.categoryTemplates li {
  display: flex;
  height: 100%;
}

@media (max-width: @screen-sm) {
  .templates, .categoryTemplates > div {
    flex-direction: column;
  }
};

.categoryName {
  color: @mainText;
  text-align: center;
  margin-top: 13/16rem;
  margin-bottom: 13/16rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 95/16rem;
}

.actions {
  text-align: right;
  margin-right: @externalMargin;
}

.onHoverActions {
  background-color: @cardHover;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  .heading, .logo, .content {
    opacity: 0.7;
  }
}

.onHoverPrimaryActions {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}

.onHoverPrimaryActionsCursor {
  cursor: pointer;
}

.primaryAction {
  flex: 4;
}

.secondaryAction {
  background-color: @paper2;
  padding-bottom: 8/16rem;
  padding-top: 8/16rem;
  width: 100%;
  text-align: center;
}

.empty {
  flex: 3;
}

.templateListTitle {
  background-color: @secondaryBackground;
  border-top: dashed @dividerLight 0.0625rem;
  border-bottom: dashed @dividerLight 0.0625rem;
  padding: 8/16rem 18/16rem;
  margin-right: 1rem;
}
