@import "../../../../resources/styles/colors";

.menuItemInternalLink {
    display: flex;
    align-items: center;

    // We don't want these to hover differently from other menu items (like buttons) just because they are links
    &:hover {
        color: inherit;
    }

    // Override color styles from material-ui
    &:global(.MuiListItem-button) {
        &:hover {
            background-color: @hover;
        }
        &:focus {
            background-color: @hover;
        }
    }
}

.icon {
    padding-right: 7/16rem;
}

.label {
    word-break: break-all;
    white-space: normal;
}

.textContainer {
    display: flex;
    flex-direction: column;
}

.description {
    font-size: 0.875rem;
    word-break: break-all;
    white-space: normal;
    color: @ternaryText;
}

.active {
    color: @primary;
}

.compact:global(.MuiMenuItem-root) {
    min-height: auto;
}

.default-size:global(.MuiMenuItem-root) {
    min-height: 48px; // copied from material-ui's base styles, that would otherwise be overriden by a media query
}

.spacious:global(.MuiMenuItem-root) {
    min-height: 64px;
    padding-top: 12px;
    padding-bottom: 12px;
}
